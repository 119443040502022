
import { mapState } from "vuex"
export default {
  props: {
    type: {
      type: String,
      default: "info",
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    read_more_title: {
      type: String,
    },
    read_more_link: {
      type: String,
    },
    vector_icon: {
      type: String,
    },
    collapse_content: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      is_content_collapse: true,
      content_expandable: false,
      line_height: 21,
    }
  },
  created() {
    this.$nextTick(() => {
      if (this.$refs.content_ref) {
        this.checkExpandable()
      }
    })
  },
  watch: {
    content(val) {
      this.$nextTick(() => {
        if (this.$refs.content_ref) {
          this.checkExpandable()
        }
      })
    },
  },
  mounted() {
    if (this.$refs.content_ref) {
      window.addEventListener("resize", this.onResize)
    }
  },
  beforeDestroy() {
    if (this.$refs.content_ref) {
      window.removeEventListener("resize", this.onResize)
    }
  },

  computed: {
    ...mapState({
      glob_icon: (state) => state.icon,
    }),
    style() {
      let style = {}

      switch (this.type) {
        case "success":
          style.color = "#46A580"
          style.background = "#E5F4EE"
          break

        case "error":
          style.color = "#E64949"
          style.background = "#FFF6F6"
          break

        case "warning":
          style.color = "#E68619"
          style.background = "#FFF6E8"
          break

        case "tips":
          style.color = "#5199E0"
          style.background = "#EDF6FC"
          style.transform = "rotate(180deg)"
          style["align-self"] = "flex-start"
          break

        // info
        default:
          style.color = "#292929"
          style.background = "#F5F5F5"

          break
      }
      return style
    },
    icon() {
      if (this.vector_icon) {
        return this.glob_icon[this.vector_icon]
      } else {
        let icon = ""

        switch (this.type) {
          case "success":
            icon = "check_circle"
            break

          case "error":
            icon = "cancel"
            break

          case "warning":
            icon = "warning_amber"
            break

          case "tips":
            icon = "tungsten"
            break

          // info
          default:
            icon = "info"

            break
        }

        return icon
      }
    },
    font_style() {
      let font_style = {}

      switch (this.type) {
        case "error":
          font_style.color = "#FC5555"
          break
        default:
          font_style.color = "#292929"
          break
      }
      return font_style
    },
  },
  methods: {
    async onResize() {
      await this.$nextTick()
      this.checkExpandable()
    },
    checkContentOverOneLine() {
      if (this.$refs.content_ref.clientHeight > this.line_height) {
        return true
      } else {
        return false
      }
    },
    isEllipsisActive() {
      if (
        this.$refs.content_ref.clientWidth < this.$refs.content_ref.scrollWidth
      ) {
        return true
      } else {
        return false
      }
    },
    checkExpandable() {
      if (!this.is_content_collapse && !this.checkContentOverOneLine()) {
        this.content_expandable = false
        this.is_content_collapse = true
      } else if (
        (this.is_content_collapse && this.isEllipsisActive()) ||
        (!this.is_content_collapse && !this.isEllipsisActive())
      ) {
        this.content_expandable = true
      } else {
        this.content_expandable = false
      }
    },
    handleExpand() {
      this.is_content_collapse = !this.is_content_collapse
    },
  },
}
