
import _ from "lodash"

const validateEmail = (rule, value, callback) => {
  const is_valid = String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )

  if (value === "") {
    callback(new Error("โปรดระบุอีเมล"))
  } else if (!is_valid) {
    callback(new Error("รูปแบบอีเมลไม่ถูกต้อง"))
  } else {
    callback()
  }
}

export default {
  props: {
    // onClickRegister: {
    //   type: Function,
    //   default: () => {},
    // },
  },
  // props: {
  //   showHeader: {
  //     type: Boolean,
  //     default: false,
  //   },
  //   header: {
  //     type: String,
  //     default: "",
  //   },
  //   image: {
  //     type: String,
  //     default: require("~/assets/images/oho-login-artwork.png"),
  //   },
  //   couldLogin: {
  //     type: Boolean,
  //     default: true,
  //   },
  //   showInvitation: {
  //     type: Boolean,
  //     default: false,
  //   },
  //   invitation: {
  //     type: Object,
  //     default: () => {
  //       return {}
  //     },
  //   },
  //   loginFacebook: {
  //     type: Function,
  //     default: () => {},
  //   },
  // },
  data() {
    return {
      is_captcha_error: false,
      loading: false,
      facebook_icon: require("~/assets/images/facebook_logo_circle.png"),
      email: "",
      form: {
        email: "",
        password: "",
      },
      rules: {
        email: [{ required: true, validator: validateEmail, trigger: "blur" }],
        password: [
          { required: true, message: "โปรดระบุรหัสผ่าน", trigger: "blur" },
        ],
      },
      is_mobile_app: false,
      is_ios: false,
      password_is_visible: false,
    }
  },
  mounted() {
    if (window.OhoFlutterConnect) this.is_mobile_app = true
    this.is_ios = navigator.userAgent.match(/iPhone|iPad|iPod/i)
  },
  methods: {
    cleanEmail(val) {
      this.form.email = val.replace(/[\u200B-\u200F\uFEFF]/g, "")
    },
    cleanPassword(val) {
      this.form.password = val.replace(/[\u200B-\u200F\uFEFF]/g, "")
    },
    cleanInvisibleCharacter() {},
    captchaError(error) {
      this.is_captcha_error = true
    },
    loginFacebook() {
      if (this.is_mobile_app) {
        const data = { action: "login-facebook" }
        window.OhoFlutterConnect.postMessage(JSON.stringify(data))
      } else {
        this.$auth.loginWith("facebook")
      }
    },
    submitForm(e) {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.onCaptcha()
      })
    },
    async onCaptcha() {
      await grecaptcha.execute()
    },
    async loginEmail(token) {
      this.loading = true
      try {
        const res = await this.$ohoApi.$post(`/authentication-user`, {
          strategy: "local",
          email: this.form.email,
          password: this.form.password,
          // captcha_response: token,
        })
        const user_id = _.get(res, "user._id")
        const access_token = _.get(res, "accessToken")
        const created_token_at = _.get(res, "authentication.payload.iat")
        this.setOhoToken(access_token, user_id, created_token_at)
        window.location.href = "/select-business"
      } catch (error) {
        await this.$recaptcha.reset()
        let message = ""
        try {
          if (error.response.status == 422) {
            message =
              "เกิดข้อผิดพลาด ไม่สามารถยืนยันว่าคุณไม่ใช่โปรแกรมอัตโนมัติ กรุณาลองใหม่อีกครั้ง"
          }
        } catch (error) {
          message =
            "เกิดข้อผิดพลาด กรุณาตรวจสอบอีเมลหรือรหัสผ่านและลองใหม่อีกครั้ง"
        }
        this.$message.error(message)
      }

      this.loading = false
    },
    setOhoToken(access_token, user_id, created_token_at) {
      const is_dev = process.env.app_env === "development"
      const max_age = 60 * 60 * 24 * 30
      let domain = process.env.web_url
        .split("//")[1]
        .split("/")[0]
        .split(":")[0]
      domain = domain.split(".").slice(1).join(".")
      let option = {
        domain,
        path: "/",
        maxAge: max_age,
      }
      if (is_dev) option = _.omit(option, ["domain"])
      this.$cookies.set("oho_user_token", access_token, option)
      this.$cookies.set("oho_user_id", user_id, option)
      this.$cookies.set("oho_created_token_at", created_token_at, option)
    },
    toggleTypePassword() {
      this.password_is_visible = !this.password_is_visible
    },
  },
}
